*{
    font-family: 'Inter';
    font-size: 14px;
}

.form-login{
    max-width: 250px;
    margin: 0 auto;
}

.form-login input{
    width: 100%;
}
.form-login button{
    width: 100%;
    margin-top: 20px;
}
.custom-list .MuiBox-root > ul{
    padding: 0;
}
.custom-list .MuiListItem-root{
    padding: 0;
    margin: 10px 0;
}
.custom-list .MuiListItem-root:first-child{
    margin: 0;
}
ul.MuiList-root.menuList{
    display: flex;
}
header .MuiToolbar-regular {
    min-height: 48px;
}
.menu .MuiList-padding {
    padding: 0;
}

.menu li:hover > a {
    color: #1890ff;
}

.menu li a{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    color: white;
}
.menu .dropdown{
    position: relative;
}
.dropdown .submenu{
    display: none;
    position: absolute;
    top: 46px;
    left: 0;
    background: #3f51b5;
/*//width: auto;*/
    white-space: nowrap;
    z-index: 999;
    padding: 0;
}
.dropdown .submenu a {
    color: #ffffff;
    text-decoration: none;
}
.dropdown .submenu a:hover {
    background: #1890ff;
    color: white;
}
.dropdown:hover .submenu{
    display: inline-block;
}
li .active{
    color: darkslateblue;
    background: #1976d2;
}

.MuiToolbar-root {
    justify-content: flex-end;
}
header .MuiToolbar-root {
    justify-content: space-between;
}

.MtTable{
    /*width: 100%;*/
}
td div div > .MuiIconButton-root{
    /*width: 24px;*/
    /*height: 24px;*/
    /*background: white;*/
    display: none;
}
td div ~ .MuiIconButton-root{
    /*width: 24px;*/
    /*height: 24px;*/
    /*background: white;*/
    display: none;
}

td .MuiIconButton-root:hover {
    background-color: rgba(63,81,181, 1);
    color: white;
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    /*min-width: 70px;*/
}

table.MuiTable-root{
    table-layout: fixed !important;
    /*font-size: 12px;*/
}

.MuiTableHead-root .MuiTableCell-root {
    /*font-size: 0.7rem;*/
    font-weight: bold;
}
div.MuiInputAdornment-root{
    display: none;
}

.MuiTableRow-root:nth-child(even){
    background-color: #F4F4F4;
}
.MuiTableRow-root.MuiTableRow-hover:hover{
    background-color: rgb(222, 243, 250) !important;
}

.spanCopy {
    display: block;
    position: relative;
    cursor: pointer;
}
.tooltipCell .spanCopy {
    position: relative;
}

.spanCopy:hover:before {
    display: block;
}

.spanCopy:before{
    content: 'Copy to Clipboard';
    display: none;
    position: absolute;
    z-index: 9999;
    top: 25px;
    left: -37px;
    width: 114px;
    height: 25px;

    color: #fff;
    font-size: 10px;
    line-height: 26px;
    text-align: center;

    background: rgba(0,0,0,.72);
    border-radius: 3px;
}
.tooltipCell .spanCopy:before{
    content: attr(name);
    width: auto;
    white-space: nowrap;
    padding: 0 10px;
    /*height: auto;*/
}
.spanCopy:focus, .spanCopy:active{
    outline: none;
}

.spanCopy:active:before, .spanCopy:focus:before {
    content: 'Copied!';
}
.tooltipCell .spanCopy:active:before, .spanCopy:focus:before{
    top: 25px;
    left: 0;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall{
    position: relative;
    padding: 5px 8px !important;
}
td.MuiTableCell-root.MuiTableCell-body div{
    width: unset !important;
    /*text-align: left;*/
    margin-left: unset !important;
    border-bottom: none !important;
}
tr#m--table--filter--row td div{
    width: 100% !important;
}
td.MuiTableCell-root {
    text-align: left;
}
.hashCell{
    display: flex;
    justify-content: space-evenly;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.MuiTableRow-root .MuiTableCell-alignRight {
    text-align: left;
    flex-direction: row;
}

td.MuiTableCell-root {
    padding: 5px 8px;
}
td.MuiTableCell-root .cell{
    /*text-align: center !important;*/
}
.bonusBlock{
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
}
.bonusBlock .MuiFormControl-root{
    max-width: 80px;
    margin-right: 10px;
}
.bonusBlock .MuiFormControl-root label{
    font-weight: bold;
}
.custom-list-col textarea{
    border: none;
}
.custom-list-col textarea:focus-visible {
    outline: 1px solid cornflowerblue;
}

.menuList a::selection {
    color: #fff;
    background: #1890ff;
}
.MuiListItem-button:target {
    text-decoration: none;
    background-color: #ddd;
}
.editCell{
    width: 100% !important;
}
.MuiInputBase-root{
    width: 100% !important;
}
input[type="file"] {
    display: none;
}
.errorBlock{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
.text-success{
    color: green;
}
.text-warning{
    color: firebrick;
}
.uploadIcon{
    display: flex;
}
.linkCheckerLabel{
    display: flex;
    align-items: center;
}
.react-datepicker__input-container input[type="text"]{
    border: none;
    background: none;
    width: 110px;
}
td select{
    border: none;
    background: none;
    /*width: 110px;*/
}
td select:hover{
    cursor: pointer;
}

.appLinkCell{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.uploadOrdersBlock{
    margin-left: 40px;
}
.paidInput {
    width: 25%;
}
.paidInput input.MuiInputBase-input{
    text-align: right;
    font-family: 'Inter';
    font-weight: bold;
    padding: 0 0 3px 0;
}

.emptyValue{
    color: silver;
}
.btnFilter{
    /*padding: 10px;*/
}

.btnFilterActive{
    /*background: #1890ff;*/
    /*color: #ffffff;*/
}
div.MuiToolbar-root button.MuiButtonBase-root[title='Clear all filters']{
    background: #1976d2;
    color: #ffffff;
    border-radius: 8px;
}
div.MuiToolbar-root.ForwardRef\(MTableToolbarRef\)-root-10 button.MuiButtonBase-root{
    border-radius: 8px;
}
.MuiTableCell-root.withFilter {
    border-bottom: 2px solid red;
}

/*Site*/
MuiTableCell-root.MuiTableCell-head{
    font-weight: 600;
}


.container{
    text-align: center;
}

.MuiTableCell-head{
    width: 80px;
}

td.headerTable{
    text-align: center;
}
td.notEditCell{
    text-align: center;
    color: silver;
}

.inputTable{
    max-width: 100%;
    border: none;
    background: none;
    text-align: center;
}
.inputTable.inputRangeFrom, .inputTable.inputRangeTo{
    max-width: 45%;
    border: 1px solid #0000001a;
}
.inputTable.inputRangeFrom{
    text-align: right;
}
.inputTable.inputRangeTo{
    text-align: left;
}

.promoHeader{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.promoHeader h3{
    margin: 0;
}
.promoName{
    margin-right: 10px;
}
.promoName label{
    margin-right: 10px;
}

button.removeBtn{
    margin-left: 10px;
}

.textField input{
    text-align: center;
    max-width: 100px;
}

.modal-filter .css-dmmspl-MuiFormGroup-root{
    flex-direction: row;
}
.modal-filter label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.css-j204z7-MuiFormControlLabel-root {
    width: 150px;
}


/*.MuiFormControl-root.MuiTextField-root.editCell {*/
/*    position: absolute;*/
/*    background: white;*/
/*    width: 120px;*/
/*    top: 0;*/
/*}*/

/*input[type="file"] {*/
/*    display: none;*/
/*}*/
/*.custom-file-upload {*/
/*    border: 1px solid #ccc;*/
/*    display: inline-block;*/
/*    padding: 6px 12px;*/
/*    cursor: pointer;*/
/*}*/


/*.app{*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*  display: flex;*/
/*}*/

/**{*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: Arial, sans-serif;*/
/*}*/
/*ul li{*/
/*  list-style-type: none;*/
/*}*/
/*ul li a {*/
/*  text-decoration: none;*/
/*}*/
/*button{*/
/*  padding: 5px 30px;*/
/*  min-width: 130px;*/
/*  border: 1px solid rgba(3, 102, 214, 0.3);*/
/*  border-radius: 2px;*/
/*  background: #ffffff;*/
/*  box-shadow: rgba(3, 102, 214, 0.3) 1px 1px 0px 2px;*/

/*  transition: all 0.5s linear;*/
/*}*/
/*button:hover{*/
/*  background: rgba(3, 102, 214, 0.3) ;*/
/*  box-shadow: rgba(3, 102, 214, 0.3)  0px 0px 0px 3px;*/
/*  border: 1px solid rgba(3, 102, 214, 0);*/
/*  color: #fff;*/
/*  cursor: pointer;*/
/*}*/

/*.sidebar{*/
/*  width: 18%;*/
/*  height: 100%;*/
/*  background: #1952a4;*/
/*  color: #ffffff;*/
/*}*/

/*.main{*/
/*  width: 82%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  padding: 20px;*/
/*  box-sizing: border-box;*/
/*  background: #e9ebf3;*/
/*}*/

/*.sidebarList{*/
/*  width: 100%;*/
/*  height: auto;*/
/*  padding: 20px 0 0 0;*/
/*}*/

/*.sidebarList .row{*/
/*  list-style-type: none;*/
/*  border: 1px solid white;*/
/*  border-radius: 5px;*/
/*  height: 40px;*/
/*  margin: 0 15px 10px 15px;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  text-align: center;*/
/*}*/
/*.sidebarList .row:hover{*/
/*  background: #144183;*/
/*  cursor: pointer;*/
/*}*/

/*.sidebarList .row a {*/
/*  color: #ffffff;*/
/*}*/


/*.flex{*/
/*  display: flex;*/
/*}*/

/*.main h1{*/
/*  font-size: 24px;*/
/*  border: 2px solid black;*/
/*  display: inline-block;*/
/*  height: 24px;*/
/*  padding: 10px;*/
/*}*/
/*.main h2{*/
/*  font-size: 22px;*/
/*  !*border: 2px solid black;*!*/
/*  display: inline-block;*/
/*  height: 24px;*/
/*  padding: 10px;*/
/*}*/

/*.container{*/
/*  padding: 20px;*/
/*  width: 1100px;*/
/*  height: 350px;*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*  justify-content: center;*/
/*  align-content: flex-start;*/
/*  position: relative;*/
/*  color: #4e598a;*/
/*  !*color: rgba(3, 102, 214, 0.8);*!*/
/*  background: #fafafc;*/
/*  border-radius: 20px;*/
/*  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);*/
/*}*/

/*.content{*/
/*  width: 100%;*/
/*  margin-top: 20px;*/
/*}*/
/*.card{*/
/*  background: #fafafc;*/
/*  border-radius: 6px;*/
/*  padding: 20px;*/
/*  color: #4e598a;*/
/*  height: max-content;*/
/*  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);*/
/*}*/
/*.card-container.card {*/
/*  max-width: 160px !important;*/
/*  padding: 40px;*/
/*}*/
/*.card-title{*/
/*   display: flex;*/
/*   justify-content: center;*/
/*}*/
/*.card .input-field{*/
/*  margin: 10px 0;*/
/*}*/
/*.card .input-field input{*/
/*  width: 130px;*/
/*  margin: 0 10px 0 0;*/
/*  box-sizing: border-box;*/
/*}*/
/*.card-action button{*/
/*  width: 100%;*/
/*}*/
/*.col-100{*/
/*flex-basis: 100%;*/
/*}*/
/*.columns{*/
/*display: flex;*/
/*  margin-bottom: 20px;*/
/*}*/
/*.col-70{*/
/*flex-basis: 70%;*/
/*}*/
/*.col-30{*/
/*  flex-basis: 50%;*/
/*}*/
/*.col-30{*/
/*  flex-basis: 30%;*/
/*}*/

/*.list{*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
/*}*/
/*.columnList{*/
/*  flex-direction: column;*/
/*}*/
/*.columnList .list-item{*/
/*  width: 26%;*/
/*  margin-top: 10px;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/
/*.list-item{*/
/*  width: 50%;*/
/*  margin-top: 20px;*/
/*}*/
/*.list-item:first-child{*/
/*  !*margin-top: 0;*!*/
/*}*/

/*.custom-checkbox {*/
/*  position: absolute;*/
/*  z-index: -1;*/
/*  opacity: 0;*/
/*}*/

/*.custom-checkbox+label {*/
/*  display: inline-flex;*/
/*  align-items: center;*/
/*  user-select: none;*/
/*}*/
/*.custom-checkbox+label::before {*/
/*  content: '';*/
/*  display: inline-block;*/
/*  width: 1em;*/
/*  height: 1em;*/
/*  flex-shrink: 0;*/
/*  flex-grow: 0;*/
/*  border: 1px solid #adb5bd;*/
/*  border-radius: 0.25em;*/
/*  margin-right: 0.5em;*/
/*  background-repeat: no-repeat;*/
/*  background-position: center center;*/
/*  background-size: 50% 50%;*/
/*}*/

/*.custom-checkbox:checked+label::before {*/
/*  border-color: #0b76ef;*/
/*  background-color: #0b76ef;*/
/*  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");*/
/*}*/

/*.notes{*/
/*  margin-bottom: 20px;*/
/*}*/
/*.btn-addNew{*/
/*  width: 300px;*/
/*  position: absolute;*/
/*  bottom: 20px;*/
/*  left: 50%;*/
/*  transform: translateX(-50%)*/
/*}*/

/*label + input {*/
/*  border: none;*/
/*  border-bottom: 1px solid rgba(3, 102, 214, 0.3);*/
/*  width: 50px;*/
/*  margin: 0 0 0 7px;*/
/*  color: #4e598a;*/
/*  text-align: end;*/
/*}*/
/*label + input:focus {*/
/*  border: none;*/
/*  border-bottom: 1px solid rgba(3, 102, 214, 0.8);*/
/*}*/
/*label + input:focus-visible {*/
/*  border: none;*/
/*  outline: none;*/
/*  border-bottom: 1px solid rgba(3, 102, 214, 0.8);*/
/*}*/

/*.react-datepicker__navigation {*/
/*  min-width: auto;*/
/*  box-shadow: none;*/
/*}*/
/*.form-group {*/
/*  margin: 5px 0;*/
/*  width: 100%;*/
/*}*/
/*.form .form-group:last-child{*/
/*  margin: 0;*/
/*}*/

/*.form-group button{*/
/*  width: 100%;*/
/*}*/

/*.alert.alert-danger {*/
/*  color: red;*/
/*}*/

/*.logout{*/
/*  background: dimgrey;*/
/*  font-weight: 600;*/
/*}*/

/*@media screen and (max-width: 800px) {*/

/*}*/