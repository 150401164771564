.container.ha{
    height: auto;
}
.paymentInfoList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1000px;
    margin: 20px 0;
}
.paymentInfo-item{
    display: inline-block;
    margin: 0 15px 20px 0;
    /*font-size: 12px;*/
    width: 150px;
}
.paymentBtn-block{
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
}
.btn-mt{
    margin-top: 10px;
}
.btn-mb{
    margin-bottom: 10px;
}
.btnXlsReport{
    justify-content: flex-end;
}

.custom-list-col{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding: 20px;
}

.custom-list-col .MuiListItem-root label{
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.custom-list-col .MuiListItem-root label span{
    font-weight: normal;
    color: #9B9B9B;
}
